<template>
  <div class="p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold">Edit User</h1>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div
        v-if="!isLoading && error"
        class="text-center text-red-600 font-semibold"
      >
        Error loading user: {{ error }}
      </div>

      <form
        v-if="!isLoading && !error"
        @submit.prevent="submitUpdate"
        class="max-w-3xl space-y-5"
      >
        <div class="text-lg space-y-5">
          <div>
            <label
              for="user_name"
              class="block text-sm font-medium text-gray-600"
            >
              Name <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="document.user_name"
                required
                name="user_name"
                class="w-full border rounded px-4 py-2"
                autocomplete="off"
              />
            </div>
          </div>

          <div>
            <label
              for="user_email"
              class="block text-sm font-medium text-gray-600"
            >
              Email <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <input
                v-model="document.user_email"
                required
                type="email"
                name="user_email"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
                autocomplete="off"
              />
            </div>
          </div>

          <div>
            <label
              for="user_role"
              class="block text-sm font-medium text-gray-600"
            >
              Role <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <select
                v-model="document.user_role"
                required
                name="user_role"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
                autocomplete="off"
              >
                <option> </option>
                <option value="admin">Admin</option>
                <option value="office">Office</option>
                <option value="estimator">Estimator</option>
              </select>
            </div>
          </div>

          <div>
            <label
              for="user_status"
              class="block text-sm font-medium text-gray-600"
            >
              Status <span class="text-red-600">*</span>
            </label>
            <div class="mt-1">
              <select
                v-model="document.user_status"
                required
                name="user_status"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
        </div>

        <div class="pt-2 flex justify-center">
          <button
            type="submit"
            class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
          >
            <div v-if="isPendingUser" class="spinner"></div>
            Save
          </button>
        </div>

        <div v-if="write_err" class="text-center text-red-600 font-semibold">
          {{ write_err }}
        </div>
      </form>

      <h1 v-if="!isLoading && !error" class="text-3xl font-semibold ">
        Update Password
      </h1>
      <form
        v-if="!isLoading && !error"
        @submit.prevent="submitPassword"
        class="max-w-3xl space-y-5"
      >
        <div class="text-lg space-y-5">
          <div>
            <label
              for="password1"
              class="block text-sm font-medium text-gray-600"
            >
              Enter new password
            </label>
            <div class="mt-1">
              <input
                v-model="document.password1"
                required
                name="password1"
                class="w-full border rounded px-4 py-2"
                autocomplete="off"
              />
            </div>
          </div>

          <div>
            <label
              for="password2"
              class="block text-sm font-medium text-gray-600"
            >
              Retype new password
            </label>
            <div class="mt-1">
              <input
                v-model="document.password2"
                required
                name="password2"
                class="w-full border rounded px-4 py-2"
                autocomplete="off"
              />
            </div>
          </div>
        </div>

        <div class="pt-2 flex justify-center">
          <button
            type="submit"
            class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
          >
            <div v-if="isPendingPassword" class="spinner"></div>
            Save Password
          </button>
        </div>

        <div
          v-if="password_msg"
          class="text-center text-green-600 font-semibold"
        >
          {{ password_msg }}
        </div>

        <div v-if="password_err" class="text-center text-red-600 font-semibold">
          {{ password_err }}
        </div>
      </form>
      <div ref="formBottom"></div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { functions } from "@/firebaseConfig";
import { useRoute, useRouter } from "vue-router";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";

export default {
  props: ["id", "user", "user_id"],
  setup(props) {
    const formBottom = ref(null);
    const isLoading = ref(true);
    const isPendingUser = ref(false);
    const isPendingPassword = ref(false);
    const route = useRoute();
    const router = useRouter();
    const password_msg = ref(null);
    const password_err = ref(null);
    const updateUser = functions.httpsCallable("updateUser");
    const setCustomUserClaims = functions.httpsCallable("setCustomUserClaims");

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, document } = getDocument(
      `businesses/${business_id}/users`,
      props.user_id
    );

    watchEffect(() => {
      if (document.value) {
        isLoading.value = false;
      }
    });

    const { error: write_err, updateDoc } = useDocument(
      `businesses/${business_id}/users`,
      props.user_id
    );

    const submitUpdate = async () => {
      write_err.value = null;
      isPendingUser.value = true;
      await updateUser({
        uid: props.user_id,
        update: {
          ...(document.value.user_status == "inactive" && { disabled: true }),
          ...(document.value.user_status == "active" && { disabled: false }),
          displayName: document.value.user_name,
          email: document.value.user_email,
        },
      })
        .then(async () => {
          await setCustomUserClaims({
            business_id: business_id,
            uid: props.user_id,
            user_role: document.value.user_role,
          });
          await updateDoc({
            user_name: document.value.user_name,
            user_email: document.value.user_email,
            user_role: document.value.user_role,
            user_status: document.value.user_status,
          });
          isPendingUser.value = false;
          if (!write_err.value) {
            router.push(route.path.substring(0, route.path.lastIndexOf("/")));
          }
        })
        .catch((err) => {
          isPendingUser.value = false;
          write_err.value = err.message;
        });
    };

    const submitPassword = async () => {
      formBottom.value.scrollIntoView({ behavior: "smooth" });

      password_err.value = null;
      document.value.password1 = document.value.password1.trim();
      document.value.password2 = document.value.password2.trim();

      if (document.value.password1 !== document.value.password2) {
        password_err.value = "Passwords do not match!";
        return;
      }
      if (document.value.password1.length < 8) {
        password_err.value = "Password is too short.";
        return;
      }
      if (document.value.password1.includes(" ")) {
        password_err.value = "Password should not include spaces.";
        return;
      }
      isPendingPassword.value = true;
      document.value.user_password = document.value.password1;
      await updateUser({
        uid: props.user_id,
        update: {
          password: document.value.user_password,
        },
      })
        .then(() => {
          document.value.password1 = null;
          document.value.password2 = null;
          password_msg.value = "Password changed.";
          isPendingPassword.value = false;
          setTimeout(() => (password_msg.value = null), 4000);
        })
        .catch((err) => {
          isPendingPassword.value = false;
          password_err.value = err.message;
        });
    };

    return {
      formBottom,
      isLoading,
      router,
      error,
      document,
      write_err,
      submitUpdate,
      password_msg,
      password_err,
      submitPassword,
      isPendingUser,
      isPendingPassword,
    };
  },
};
</script>
